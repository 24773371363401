export const menuList = [
  {
    name: "Dashboard",
    icon: "dashboard",
  },
  {
    name: "Subscriptions",
    icon: "subscriptions",
  },
  {
    name: "Plans",
    icon: "style",
  },
  {
    name: "Unsubscribe",
    icon: "unsubscribe",
  },
  {
    name: "Gallery Images",
    icon: "collections",
  },
  {
    name: "FAQ",
    icon: "quiz",
  },
  {
    name: "Business Settings",
    icon: "settings",
  },
  {
    name: "Phone Number",
    icon: "phone",
    phoneNumber: "9481815122", 
  },
];
