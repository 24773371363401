import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./components/home/home";
import Login from "./components/login/login";
import Splash from "./components/splash/splash";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Otp from "./components/otp/otp";

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 5); // Adjust the delay time as needed
  }, []);


  return (
    <div
    className="App"
    style={{
      visibility: isLoading ? "hidden" : "visible",
      opacity: isLoading ? 0 : 1,
    }}
  >
    <Router>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </Router>
  </div>
  );
}

export default App;






