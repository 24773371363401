
import "./gallery.css";
import React, { useState, useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { baseUrl } from "../../../../network/constants/constants";
import axios from "../../../../network/api";
import config from "../../../../network/auth";
import AppConstants from "../../../AppConstants";
import Spinner from "../loader/loader";

function Gallery() {
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [previewImage, setPreviewImage] = useState(null); // To store the preview URL
  const [validationError, setValidationError] = useState({
    gallery_image: ""
  });
  let bID = localStorage.getItem(AppConstants.business_id);

  // delete image
  const deleteImage = (imageId) => {
    setLoading(true);

    axios
      .delete(baseUrl + `app/gallery/delete/${imageId}/`, config)
      .then((response) => {
        setImageList(imageList.filter((image) => image.id !== imageId));
        setLoading(false);
      })
      .catch((error) => {
        console.error("image Error:", error);
        setLoading(false);
      });
  };

  const fetchGallery = () => {
    setLoading(true);
    axios
      .get(baseUrl + `app/gallery-list-by-id/${bID}/`, config)
      .then((response) => {
        setLoading(false);
        setImageList(response.data);
      })
      .catch((error) => {
        console.error("Gallery Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  // post banner
  const handleGallerySubmit = (event, close) => {
    event.preventDefault();
    setValidationError({ gallery_image: "" });

    if (!selectedFile) {
      setValidationError({ gallery_image: "Please select an image to upload." });
      return;
    }

    const formData = new FormData();
    formData.append("gallery_images", selectedFile);
    formData.append("b_id", bID);
    setLoading(true);

    axios
      .post(`${baseUrl}app/gallery/create/`, formData, config)
      .then((response) => {
        setLoading(false);
        fetchGallery();
        setImageList([...imageList, response.data]);
        setPreviewImage(null); // Clear the preview after submission
        setSelectedFile(null); // Clear the selected file
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setValidationError({ gallery_image: "" });
    if (file) {
      setPreviewImage(URL.createObjectURL(file)); // Set preview image
    } else {
      setPreviewImage(null); // Clear preview if no file is selected
    }
  };

  return (
    <div className="main-div">
      {loading ? (
        <div className="loader-container">
          <Spinner />
        </div>
      ) : (
        <>
          {imageList.length > 0 ? (
            <div style={{ padding: "10px" }}>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry gutter="20px">
                  {imageList.map((image, i) => (
                    <div className="gallery-img-div" key={image.id}>
                      <Popup
                        className="popup-container"
                        contentStyle={{
                          borderRadius: "20px",
                          backgroundColor: "white",
                        }}
                        trigger={
                          <span
                            className="material-icons delete-icon"
                            onClick={() => deleteImage(image.id)}
                          >
                            delete_forever
                          </span>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="delete-img-div">
                            <h3 className="delete-img-heading">
                              Are you sure?
                            </h3>
                            <p className="delete-img-p">
                              Do you really want to delete?
                            </p>
                            <div className="delete-btn-div">
                              <button
                                onClick={() => {
                                  deleteImage(image.id);
                                  close();
                                }}
                              >
                                Delete
                              </button>
                              <button onClick={close}>Cancel</button>
                            </div>
                          </div>
                        )}
                      </Popup>
                      <img
                        src={`${baseUrl}${image.gallery_images}`}
                        style={{
                          width: "100%",
                          display: "block",
                          cursor: "pointer",
                        }}
                        alt="images"
                      />
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          ) : (
            <p style={{ textAlign: "center" }}>No images available</p>
          )}

          <Popup
            className="add-popup-container"
            contentStyle={{ borderRadius: "20px" }}
            trigger={<button className="btn-add">+ Add Image</button>}
            modal
            nested
          >
            {(close) => (
              <div className="add-img-div">
                <form
                  method="post"
                  onSubmit={(e) => { handleGallerySubmit(e, close); }}
                >
                  <h3 className="add-img-heading">Add gallery image</h3>
                  <label className="add-file-upload">
                    <input
                      className="add-img-file"
                      id="file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <MdOutlineFileUpload className="upload-icon" />
                  </label>
                  {validationError.gallery_image && (
                    <p className="error-text">{validationError.gallery_image}</p>
                  )}

                  {/* Preview Image */}
                  {previewImage && (
                    <div className="preview-container">
                      <div>Image Preview:</div>
                      <img
                        src={previewImage}
                        alt="Selected"
                        style={{ width: "50%", height: "100px" }}
                      />
                    </div>
                  )}

                  <div className="submit-banner-file-container">
                    <button type="submit">Upload</button>
                    <button type="button" onClick={close}>
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            )}
          </Popup>
        </>
      )}
    </div>
  );
}

export default Gallery;





