import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./sub_plan_screen.css";
import SubPlanComponent from "./components/sub_plan_component/sub_plan_component";
import { Button } from "@mui/material";
import Popup from "reactjs-popup";
import { MdClose } from "react-icons/md";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import axios from "../../../../../network/api";
import { baseUrl } from "../../../../../network/constants/constants";
import config from "../../../../../network/auth";
import AppConstants from "../../../../AppConstants";

const SubPlanScreen = ({ onPageChange, planId }) => {
  const [subPlans, setSubPlans] = useState([]);
  const [newSubPlan, setNewSubPlan] = useState({
    sub_plan_title: "",
    price: "",
    validity: "",
    visits: "",
    plan_info: [],
  });
  const [newPlanInfo, setNewPlanInfo] = useState([{ info: "" }]);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState({
    sub_plan_title: "",
    price: "",
    validity: "",
    visits: "",
    plan_info: "",
  });

  

  const bID = localStorage.getItem(AppConstants.business_id);

  useEffect(() => {
    fetchSubPlans();
  }, [planId]);

  const handlePlanClick = () => {
    onPageChange("plans");
  };

  // const handleAddChange = (event) => {
  //   const { name, value } = event.target;
  //   setNewSubPlan({
  //     ...newSubPlan,
  //     [name]: value,
  //   });
  //   setValidationError((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: "",
  //   }));
  // };

  // const handleInfoChange = (index, event) => {
  //   const values = [...newPlanInfo];
  //   values[index].info = event.target.value;
  //   setNewPlanInfo(values);
  //   setValidationError((prevErrors) => ({
  //     ...prevErrors,
  //     plan_info: "",
  //   }));
  // };

  // const handleAddPlanInfoChange = () => {
  //   setNewPlanInfo([...newPlanInfo, { info: "" }]);
  // };

  // const handleRemovePlanInfo = (index) => {
  //   const values = [...newPlanInfo];
  //   values.splice(index, 1);
  //   setNewPlanInfo(values);
  // };

  // const handleSubmit = (event, close) => {
  //   event.preventDefault();
  //   if (validateForm()) {
  //     handleAddSubplan(close);
  //   }
  // };

  // delete subplan
  const deleteSubPlan = (subPlanId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/subplan/delete/${subPlanId}/`, config)
      .then((response) => {
        setSubPlans((prevSubPlans) =>
          prevSubPlans.filter((subplan) => subplan.id !== subPlanId)
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Subplans Error:", error);
        setLoading(false);
      });
  };

  // patch subplan

  const editSubPlan = (subPlanId, editedSubPlan, close) => {
    setLoading(true);

    const payload = {
      sub_plan_title: editedSubPlan.sub_plan_title,
      price: editedSubPlan.price,
      validity: editedSubPlan.validity,
      visits: editedSubPlan.visits,
      plan_info: editedSubPlan.plan_info,
      plan_id: planId,
      b_id: bID,
    };

    axios
      .patch(`${baseUrl}app/subplan/edit/${subPlanId}/`, payload, config)
      .then((response) => {
        fetchSubPlans();
        response.data.forEach((editedsubPlan) => {
          console.log(editedsubPlan);
        });
        console.log("<<<<" + response.data);
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("Subplans Error:", error);
        setLoading(false);
      });
  };

  // post subplan

  // const handleAddSubplan = (close) => {
  //   setLoading(true);

  //   const payload = {
  //     sub_plan_title: newSubPlan.sub_plan_title,
  //     price: newSubPlan.price,
  //     validity: newSubPlan.validity,
  //     visits: newSubPlan.visits,
  //     plan_info: newPlanInfo.map((item) => item.info).join(", "),
  //     plan_id: planId,
  //     b_id: bID,
  //   };

  //   axios
  //     .post(`${baseUrl}app/subplan/create/`, payload, config)
  //     .then((response) => {
  //       fetchSubPlans();
  //       setNewSubPlan({
  //         sub_plan_title: "",
  //         price: "",
  //         validity: "",
  //         visits: "",
  //         plan_info: [],
  //       });
  //       setNewPlanInfo([{ info: "" }]);
  //       setValidationError({
  //         sub_plan_title: "",
  //         price: "",
  //         validity: "",
  //         visits: "",
  //         plan_info: "",
  //       });
  //       setLoading(false);
  //       close();
  //     })
  //     .catch((error) => {
  //       console.error("Subplans Error:", error);
  //       setLoading(false);
  //     });
  // };

  // get subplan
  const fetchSubPlans = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/subplan-by-plan/${planId}/`, config)
      .then((response) => {
        setSubPlans(response.data);
        // response.data.forEach(subPlan => {
        //   console.log(subPlan);
        // });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Subplans Error:", error);
        setLoading(false);
      });
  };

  return (
    
    <div>
      <h1>My SubPlan</h1>
      <Button className="go-back" onClick={handlePlanClick}>
        Go Back
      </Button>
      <Container fluid>
        <div className="sub-plan-container">
          {subPlans.length > 0 ? (
            subPlans.map((subplan) => (
              <SubPlanComponent
                key={subplan.id}
                id={subplan.id}
                sub_plan_title={subplan.sub_plan_title}
                price={subplan.price}
                validity={subplan.validity}
                visits={subplan.visits}
                plan_info={subplan.plan_info}
                thumb={subplan.thumb}
                onDelete={deleteSubPlan}
                onEditPlan={editSubPlan}
                planId={planId}
              />
            ))
          ) : (
            <div>
              <p className="clean">No Sub plans</p>
            </div>
          )}
        </div>


        <Popup
          contentStyle={{ borderRadius: "20px" }}
          trigger={<button className="btn-add">+ Add Subplans</button>}
          modal
          nested
        >
          {(close) => (
            <div className="no-permission-div">
            <h3 className="no-permission">You do not have permission. Only the super admin has permission.</h3>
              <div className="delete-btn-div">
            
                <Button onClick={close} className="ok-btn">
                  ok
                </Button>
              </div>
            </div>
          )}
        </Popup>
        
        {/* <Popup
          className="add-popup-container"
          trigger={<button className="btn-add">+ Add sub plans</button>}
          modal
          nested
        >
          {(close) => (
            <div>
              <div className="close-btn" onClick={close}>
                <MdClose />
              </div>
              <div className="add-plan-div">
                <form onSubmit={(e) => handleSubmit(e, close)}>
                  <div className="heading">Add Sub Plan</div>
                  <Box>
                    <TextField
                      className="plan-form-textfield"
                      name="sub_plan_title"
                      label="Subplan Name"
                      variant="outlined"
                      size="small"
                      value={newSubPlan.sub_plan_title}
                      onChange={handleAddChange}
                      error={!!validationError.sub_plan_title}
                      helperText={validationError.sub_plan_title}
                    />
                    <TextField
                      className="plan-form-textfield"
                      name="price"
                      label="Price"
                      variant="outlined"
                      size="small"
                      value={newSubPlan.price}
                      onChange={handleAddChange}
                      type="number"
                      error={!!validationError.price}
                      helperText={validationError.price}
                    />
                    <TextField
                      className="plan-form-textfield"
                      name="validity"
                      label="Validity"
                      variant="outlined"
                      size="small"
                      value={newSubPlan.validity}
                      onChange={handleAddChange}
                      error={!!validationError.validity}
                      helperText={validationError.validity}
                    />
                    <TextField
                      className="plan-form-textfield"
                      name="visits"
                      label="Number of Visits"
                      variant="outlined"
                      size="small"
                      value={newSubPlan.visits}
                      onChange={handleAddChange}
                      type="number"
                      error={!!validationError.visits}
                      helperText={validationError.visits}
                    />
                    <div className="plan-info">Plan Info</div>
                    {newPlanInfo.map((plan, index) => (
                      <div className="wrapper" key={index}>
                        <TextField
                          name="info"
                          label="Plan Info"
                          variant="outlined"
                          className="planinfo-form-textfield"
                          size="small"
                          value={plan.info}
                          onChange={(e) => handleInfoChange(index, e)}
                          error={!!validationError.plan_info}
                          helperText={validationError.plan_info}
                        />
                        <span
                          onClick={() => handleRemovePlanInfo(index)}
                          className="material-icons remove-icon"
                        >
                          delete_forever
                        </span>
                      </div>
                    ))}
                    <div className="plan-info-btn-container">
                      <Button
                        className="add-plan-info"
                        onClick={handleAddPlanInfoChange}
                      >
                        + Plan Info
                      </Button>
                    </div>
                    <Button className="plan-submit-btn" type="submit">
                      Submit
                    </Button>
                  </Box>
                </form>
              </div>
            </div>
          )}
        </Popup> */}
      </Container>
    </div>
  );
};

export default SubPlanScreen;
