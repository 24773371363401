
import React, { useState } from "react";
import PlanScreen from "../plans/plan_screen/plan_screen";
import SubPlanScreen from "../plans/sub_plan_screen/sub_plan_screen";

const MainScreen = () => {
  const [currentPage, setCurrentPage] = useState("plans");
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const handlePageChange = (page, planId = null) => {
    setCurrentPage(page);
    setSelectedPlanId(planId);
  };

  return (
    <div>
      {currentPage === "plans" && <PlanScreen onPageChange={handlePageChange} />}
      {currentPage === "subplans" && (
        <SubPlanScreen onPageChange={handlePageChange} planId={selectedPlanId} />
      )}
    </div>
  );
};

export default MainScreen;
